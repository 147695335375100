import '@css/main.scss';
import '@js/bootstrap';
import {Header} from "@js/utils/Header";
import {Parallax} from "@js/utils/Parallax";
import {Burger} from "@js/utils/Burger";


window.onload = () => {
  new Header()
  new Parallax()
  new Burger()
}

export class Parallax {
  constructor() {
    window.addEventListener('scroll', throttle(parallax() ,14));

    function throttle(fn, wait) {
      let time = Date.now();
      return function () {
        if ((time + wait - Date.now()) < 0) {
          parallax();
          time = Date.now()
        }
      }
    }

    function parallax() {
      const overlayHeader = document.querySelectorAll('[data-parallax]');
      let scrolled = window.pageYOffset;
      if (overlayHeader != null) {
        let delay = 0.35;
        let coords = (scrolled * delay) + 'px';
        let opacity = (1 - (scrolled/100) / 4);

        overlayHeader.forEach(function (e) {
          e.style.transform = 'translateY(' + coords + ')';
          e.style.opacity = opacity
        })
      }
    }
  }
}

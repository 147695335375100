export class Header {
  constructor() {
    this.header = document.querySelector('.header');

    if (this.header) {
      this.init();
    }
  }

  init() {

    window.addEventListener('scroll', () => {

      if (window.scrollY > 50) {
        this.header.classList.add('header--scrolled');
      } else {
        this.header.classList.remove('header--scrolled');
      }
    });
  }
}
